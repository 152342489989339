<template>
  <div class="app-list-container">
    <div class="header">
      <el-col class="create-option">
        <el-button
          type="primary"
          @click="
            postForm = {};
            showCreateDialog = true;
          "
        >
          添加广告
        </el-button>
      </el-col>
    </div>

    <el-table :data="adList.items" border stripe>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.pic_url" width="80" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            v-if="+scope.row.status > 0"
            size="mini"
            type="warning"
            @click="shelf(scope.row)"
          >
            下架
          </el-button>
          <el-button
            v-if="+scope.row.status <= 0"
            size="mini"
            type="success"
            @click="shelf(scope.row)"
          >
            上架
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="adList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      :title="isEdit ? '编辑广告' : '添加广告'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="(showCreateDialog = false), (isEdit = false)"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="postForm.title"></el-input>
        </el-form-item>
        <el-form-item label="应用图标">
          <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :limit="1"
            :file-list="appIconList"
            :on-success="uploadSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="postForm.link"></el-input>
        </el-form-item>
        <el-form-item label="排序权重">
          <el-input v-model="postForm.sort" type="number"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postAd, editAd, deleteAd, shelfAd } from "@/api/ad";

export default {
  data() {
    return {
      columns: [
        { id: "title", label: "标题" },
        {
          id: "status",
          label: "状态",
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return <el-tag>下架</el-tag>;
              case 1:
                return <el-tag type="success">上架</el-tag>;
              default:
                return row.status;
            }
          },
        },
      ],
      postForm: {},
      status: 0,
      isEdit: false,
      showCreateDialog: false,
      currentPage: 1,
      pageSize: 30,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["adList"]),
    appIconList() {
      if (this.postForm.pic_url) {
        return [
          {
            name: "pic_url",
            url: this.postForm.pic_url,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    fetchData() {
      let query = {
        all: 1,
      };
      query.page = this.currentPage;
      query.page_size = this.pageSize;
      this.$store.dispatch("ad/getAdList", query);
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handleEdit(data) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        id: data.id,
        title: data.title,
        pic_url: data.pic_url,
        link: data.link,
        sort: data.sort,
      };
    },
    handleDelete(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteAd(row.id).then(() => {
          this.$message({
            message: "删除成功",
            duration: 3000,
            type: "success",
          });
          this.fetchData();
        });
      });
    },
    uploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.postForm.pic_url = data.data;
      }
    },
    onSubmit() {
      if (this.isEdit) {
        editAd(this.postForm).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      } else {
        postAd(this.postForm).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      }
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.isEdit = false;
      this.fetchData();
    },
    shelf(row) {
      const { id, status } = row;
      shelfAd({ status: +status ? 0 : 1, id: id }).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: "操作失败",
            duration: 3000,
            type: "error",
          });
        } else {
          this.$message({
            message: "操作成功",
            duration: 3000,
            type: "success",
          });
        }
        this.fetchData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-list-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
